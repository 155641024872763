class EventEmitter {
	listeners: { [key: string]: Array<(...args: any[]) => void> };

	constructor() {
		this.listeners = {};
	}

	addListener(eventName: string, listener: (...args: any[]) => void) {
		if (!this.listeners[eventName]) {
			this.listeners[eventName] = [];
		}
		this.listeners[eventName].push(listener);
	}

	removeListener(eventName: string, listener: (...args: any[]) => void) {
		if (this.listeners[eventName]) {
			this.listeners[eventName] = this.listeners[eventName].filter((l) => l !== listener);
		}
	}

	emit(eventName: string, ...args: any[]) {
		if (this.listeners[eventName]) {
			this.listeners[eventName].forEach((listener) => {
				listener(...args);
			});
		}
	}
}

export default new EventEmitter();
