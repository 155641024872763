import { DateTime } from "luxon";

// Function to format date from timestamp (in seconds or milliseconds)
const formatDateTime = (timestamp, format, timezoneId?) => {
	// Check if timestamp is null or undefined
	if (timestamp === null || timestamp === undefined) {
		return "-"; // No timestamp provided
	}
	let dateTime;
	// Determine if timestamp is in seconds or milliseconds
	if (timestamp.toString().length === 10) {
		if (timezoneId) {
			// Convert seconds to milliseconds with Time Zone
			dateTime = DateTime.fromSeconds(timestamp).setZone(timezoneId);
		} else {
			// Convert seconds to milliseconds
			dateTime = DateTime.fromSeconds(timestamp);
		}
	} else if (timestamp.toString().length === 13) {
		if (timezoneId) {
			// Convert milliseconds to seconds with Time Zone
			dateTime = DateTime.fromMillis(timestamp).setZone(timezoneId);
		} else {
			// Convert milliseconds directly
			dateTime = DateTime.fromMillis(timestamp);
		}
	} else {
		// Handle invalid timestamps or other cases
		return "-"; // Invalid timestamp
	}

	// Format DateTime object as needed
	if (dateTime.isValid && timezoneId && timezoneId !== "Asia/Calcutta") {
		return dateTime.toFormat(`${format} ZZZZ`);
	} else if (dateTime.isValid && timezoneId === "Asia/Calcutta") {
		return dateTime.toFormat(format).concat(" IST");
	} else if (dateTime.isValid && !timezoneId) {
		return dateTime.toFormat(format);
	} else {
		return "-"; // Invalid date
	}
};

const formatCustomDateTime = (timestamp, format, timezoneId?) => {
	// Function to check if the input is a Unix timestamp
	const isUnixTimestamp = (value) => {
		return /^\d+$/.test(value);
	};

	// Convert input to Unix timestamp if it is in ISO 8601 format
	const convertToUnixTimestamp = (value) => {
		if (isUnixTimestamp(value)) {
			// If it is already a Unix timestamp, return it
			return parseInt(value, 10);
		} else {
			// Otherwise, convert the ISO 8601 string to Unix timestamp
			const dateTime = DateTime.fromISO(value, { zone: "UTC" });
			return dateTime.ts;
		}
	};

	const unixTimestamp = timestamp ? convertToUnixTimestamp(timestamp.slice(0, -1).toUpperCase()) : null;

	let dateTime;
	// Determine if timestamp is in seconds or milliseconds
	if (unixTimestamp && unixTimestamp.toString().length === 10) {
		if (timezoneId) {
			// Convert seconds to milliseconds with Time Zone
			dateTime = DateTime.fromSeconds(unixTimestamp).setZone(timezoneId);
		} else {
			// Convert seconds to milliseconds
			dateTime = DateTime.fromSeconds(unixTimestamp);
		}
	} else if (unixTimestamp && unixTimestamp.toString().length === 13) {
		if (timezoneId) {
			// Convert milliseconds to seconds with Time Zone
			dateTime = DateTime.fromMillis(unixTimestamp).setZone(timezoneId);
		} else {
			// Convert milliseconds directly
			dateTime = DateTime.fromMillis(unixTimestamp);
		}
	} else {
		// Handle invalid timestamps or other cases
		return "-"; // Invalid timestamp
	}
	// Format DateTime object as needed
	if (dateTime.isValid && timezoneId && timezoneId !== "Asia/Calcutta") {
		return dateTime.toFormat(`${format} ZZZZ`);
	} else if (dateTime.isValid && timezoneId === "Asia/Calcutta") {
		return dateTime.toFormat(format).concat(" IST");
	} else if (dateTime.isValid && !timezoneId) {
		return dateTime.toFormat(format);
	} else {
		return "-"; // Invalid date
	}
};

export { formatDateTime, formatCustomDateTime };
