import qs from "qs";

// default query parameters type
type DefaultQueryParams = {
	[key: string]: string | number | boolean | object | undefined; // Allow any properties
};

// Query parameters type for ES queries
type EsQueryParams = {
	offset?: number;
	size?: number;
	fields?: string;
	filter?: string;
	sort?: { id: string; desc: boolean }[];
	raw?: object;
};

// Query parameters type for RBQL
type RbqlQueryParams = {
	rbql?: object;
};

// Query parameters type for RBQL
type SelectorQueryParams = {
	selector: object;
};

/**
 * Interface for query parameter strategy
 * Add method here for which implementation will be in individual class
 * */
interface QueryParamStrategy {
	generate(params: DefaultQueryParams): string;
}

/**
 * DefaultQueryParamStrategy class
 * Which accepts params as {key1: "value1", key2: "value2"} and generates queryParam as key1=value1&key2=value2
 */
class DefaultQueryParamStrategy {
	/**
	 * Removes empty values from the provided object.
	 * @param obj Object from which to remove empty values.
	 * @returns Object with empty values removed.
	 */
	protected removeEmptyValues<T extends DefaultQueryParams>(obj: T): Partial<T> {
		const cleaned: Partial<T> = {};
		Object.entries(obj).forEach(([key, value]) => {
			if (value !== undefined && value !== null && value !== "") {
				cleaned[key as keyof T] = value as T[keyof T];
			}
		});
		return cleaned;
	}

	/**
	 * Generates query string from default query parameters.
	 * @param params Default query parameters as {key1: "value1", key2: "value2"}
	 * @returns Generated query string as key1=value1&key2=value2
	 */
	generate(params: DefaultQueryParams): string {
		const cleanedParams = this.removeEmptyValues(params);
		return qs.stringify(cleanedParams, { encode: true });
	}
}

/**
 * EsQueryParamStrategy Class
 * Generates query string specific to ES query parameters, for ES API
 */
class EsQueryParamStrategy extends DefaultQueryParamStrategy {
	generate(params: EsQueryParams): string {
		// Construct the query object
		const queryObject = {
			$offset: params.offset,
			$size: params.size,
			$fields: params.fields,
			$filter: params.filter,
			$orderBy: this.generateOrderByParamString(params.sort),
			$raw: JSON.stringify(params.raw)
		};

		const cleanedParams = this.removeEmptyValues(queryObject);
		return qs.stringify(cleanedParams, { encode: true });
	}

	/**
	 * Generates order by parameter string.
	 * @param sort Sorting criteria.
	 * @returns Order by parameter string.
	 */
	private generateOrderByParamString(sort: { id: string; desc: boolean }[]): string | undefined {
		if (!sort || sort.length === 0) {
			return undefined;
		}

		const sortField = sort[0].id;
		const sortOrder = sort[0].desc ? "desc" : "asc";

		return `${sortField} ${sortOrder}`;
	}
}

/**
 * RbqlQueryParamStrategy class.
 * Generates query string specific to RBQL query parameters, for autocrud API
 */
class RbqlQueryParamStrategy extends DefaultQueryParamStrategy {
	generate(params: RbqlQueryParams): string {
		const cleanedParams = this.removeEmptyValues(params);
		try {
			const rbql = cleanedParams.rbql ? JSON.stringify(cleanedParams.rbql) : undefined;
			return qs.stringify({ rbql }, { encode: true });
		} catch (error) {
			// Handle error appropriately (throw, log, or set default value)
			console.error("Error serializing JSON:", error);
			return ""; // Return default value
		}
	}
}

/**
 * SelectorQueryParamStrategy class.
 * Generates query string specific to Selector query parameters, for Selector API
 */
class SelectorQueryParamStrategy extends DefaultQueryParamStrategy {
	generate(params: SelectorQueryParams): string {
		try {
			return "selector=" + JSON.stringify(params.selector);
		} catch (error) {
			// Handle error appropriately (throw, log, or set default value)
			console.error("Error serializing JSON:", error);
			return ""; // Return default value
		}
	}
}

/**
 * Creates query parameter strategy based on provided parameters.
 * @param params Default query parameters.
 * @returns Query parameter strategy instance.
 * In future, we may need to improve this based on API type.
 * As of now, it supports Default, ES and RBQL
 */
function createQueryParamStrategy(params: DefaultQueryParams): QueryParamStrategy {
	if (params.rbql) {
		return new RbqlQueryParamStrategy();
	} else if (params.selector) {
		return new SelectorQueryParamStrategy();
	} else if (params.offset !== undefined || params.size !== undefined || params.fields || params.raw || params.filter || params.sort) {
		return new EsQueryParamStrategy();
	}
	return new DefaultQueryParamStrategy();
}

/**
 * Entry point function which generated queryPram string based on params
 * Generates query string based on provided default query parameters.
 * @param params Default query parameters.
 * @returns Generated query string.
 */
function generateQueryParams(params: DefaultQueryParams): string {
	const strategy = createQueryParamStrategy(params);
	return strategy.generate(params);
}

export default generateQueryParams;
